import appConfig from './AppConfiguration'
import { initializeApp } from 'firebase/app';
import {getAuth} from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseApp = initializeApp(appConfig.firebaseConfig);
const firebaseAuth = getAuth(firebaseApp);
const firebaseDb = getDatabase(firebaseApp);

const firebaseAnalytics = getAnalytics();

const logMyEvent = (eventName, data) => {
    // logEvent(analytics, 'select_content', {
    //   content_type: 'image',
    //   content_id: 'P12453'
    // });
    logEvent(firebaseAnalytics, eventName, data);
}

export {firebaseAuth, firebaseDb, logMyEvent};
