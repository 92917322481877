import {signInWithEmailPassword} from "./FirebaseUtils";
import {getLang} from "./CommonUtils";
import * as errorConstants from './../error/ErrorConstants'
import {AppError} from "../error/AppError";


function getErrorResponse(response) {
    return response.text()
        .then(respText => {
            const e = JSON.parse(respText);
            throw new AppError(e.message, e.detail, e.type, e.violations)
        })
        .catch(error => {
            if (error instanceof AppError)
                throw error
            throw new AppError("Unexpected Error", null, errorConstants.LOGIN_SERVER_ERROR_GENERIC)
        })
}

export function checkEmailExists(email) {
    //TODO change to login server impl
    return signInWithEmailPassword(email, "abcdedfgh")
        .then((userCredential) => {
            return true;
        }).catch((error) => {
            if (error.code == 'auth/user-not-found')
                return false;
            else
                return true;
        });
}

export function sendEmailSignUpGenerateOTP(email) {
    return sendOTP("/wauth/signup-email/generate-otp?lang=" + getLang(), JSON.stringify({
        "email": email
    }));
}

export function sendEmailUpdateOTP(email) {
    return sendOTP("/wauth/update-email/generate-otp?lang=" + getLang(), JSON.stringify({
        "email": email
    }));
}

export function sendPhoneUpdateOTP(phone_country_code, phone_number, claim_if_exists=false) {
    var cieParam = "cie=" + (claim_if_exists?1:0);
    return sendOTP("/wauth/update-phone/generate-otp?lang=" + getLang()+"&"+cieParam, JSON.stringify({
        "phone_country_code": phone_country_code,
        "phone_number": phone_number
    }));
}

function sendOTP(endpoint, data, headers) {

    return fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: data
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                return getErrorResponse(response)
            }
        });
}

function verifyOTP(requestId, otp, endpoint, headers) {
    return fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            otp: otp,
            requestId: requestId,
        })
    }).then(response => {
        if (response.ok) {
            return response.json();
        } else {
            return getErrorResponse(response)
        }

    });
}

export function verifyEmailSignupOtp(requestId, otp, newUser) {
    return verifyOTP(requestId, otp, "/wauth/signup-email/verify-otp?newUser="+newUser);
}

export function setNewAccountPassword(idToken, password) {
    return fetch("/wauth/signup-email/set-new-account-password", {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + idToken
        },
        body: JSON.stringify({
            password: password,
        })
    }).then(response => {
        if (response.ok) {
            return;
        } else {
            return getErrorResponse(response)
        }

    });
}

export function resendOtp(requestId) {
    return fetch("/wauth/resend-otp?lang=" + getLang(), {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            requestId: requestId,
        }),
    }).then(response => {
        if (response.ok) {
            return;
        } else {
            return getErrorResponse(response)
        }

    });
}

export async function resetEmailUsingProviderApiPromise(tokenId) {
    const headers = new Headers({
        "Content-Type": "application/json",
        Authorization: "Bearer " + tokenId,
    });

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({}),
    };

    return fetch("/reset-email-using-provider", options)
        .then(response => {
            if (Math.floor(response.status / 100) === 2) {
                return response.json()
            } else {
                return getErrorResponse(response)
            }
        })
}

export async function checkResetPasswordLinkValidity(otcToken) {
    const headers = new Headers({
        "Content-Type": "application/json"
    });

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            otc: otcToken
        }),
    };

    return fetch("/crpv", options)
        .then(response => {
            if (Math.floor(response.status / 100) === 2) {
                return response.json()
            } else {
                return getErrorResponse(response)
            }
        })
}

export async function confirmPasswordReset(otcToken, newPassword) {
    const headers = new Headers({
        "Content-Type": "application/json"
    });

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            otcToken: otcToken,
            newPassword: newPassword
        }),
    };

    return fetch("/cpr", options)
        .then(response => {
            if (Math.floor(response.status / 100) !== 2)
                return getErrorResponse(response)
        })
}


export function sendPhoneSignUpOTP(phone_country_code, phone_number) {
    return sendOTP("/wauth/signup-phone/generate-otp?lang=" + getLang(), JSON.stringify({
        phone_country_code: phone_country_code,
        phone_number: phone_number,
      }));
}

export function sendPhoneOTP(phone_country_code, phone_number) {
    return sendOTP("/wauth/login-phone/generate-otp?lang=" + getLang(), JSON.stringify({
        phone_country_code: phone_country_code,
        phone_number: phone_number,
      }));
}

export function verifyPhoneSignupOtp(requestId, otp) {
    return verifyOTP(requestId, otp, "/wauth/signup-phone/verify-otp");
}

export function verifyPhoneLoginOtp(requestId, otp) {
    return verifyOTP(requestId, otp, "/wauth/login-phone/verify-otp");
}

export function verifyPhoneUpdateOtp(requestId, otp) {
    return verifyOTP(requestId, otp, "/wauth/update-phone/verify-otp");
}

export function verifyEmailUpdateOtp(requestId, otp) {
    return verifyOTP(requestId, otp, "/wauth/update-email/verify-otp");
}

export function createProfile( userId, data, tokenId ) {

    return fetch("/addtopms", {
        method: "POST",
        headers: {
            "Content-Type": 'application/json',
            "x-user": userId,
            "Authorization": "Bearer " + tokenId,
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                return getErrorResponse(response)
            }
        });
}

export function createSession( data ) {
    var form_data = new URLSearchParams();
    for (var k in data) {
        form_data.append(k, data[k]);
      }
    return fetch("/sm", {
        method: "POST",
        headers: {
            "Content-Type": 'application/x-www-form-urlencoded',
            // "x-user": userId
        },
        body: form_data.toString()
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                return getErrorResponse(response)
            }
        });
}

export function checkEmailAvailability(email, tokenId){
    let body = {
        "mode": "email",
        "email" : email
      };
    let headers = {
        "Content-Type": 'application/json'
    };
    if(tokenId){
        headers['Authorization'] =  "Bearer " + tokenId;
    }
    return fetch("/lep/caewp", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body)
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                return getErrorResponse(response)
            }
        });
}

export function checkPhoneAvailability(phone, tokenId){
    let body = {
        "mode": "phone",
        "phone": phone
      };
    let headers = {
        "Content-Type": 'application/json'
    };
    if(tokenId){
        headers['Authorization'] = "Bearer " + tokenId
    }
    return fetch("/lep/caewp", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body)
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                return getErrorResponse(response)
            }
        });
}


export function checkProfileExists(profileId, tokenId){
    return fetch("/profilecheck?profileId="+profileId, {
        method: "POST",
        headers: {
            "Content-Type": 'application/json',
            Authorization: "Bearer " + tokenId,
        }
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                return getErrorResponse(response)
            }
        }).then((profileResponse)=>{
            // console.log('success case');
            // console.log(profileResponse);
            return profileResponse.profileExists;
        }).catch((error)=>{
            // console.log(error.stack);
            if(error.code && error.code == "error.profile/user-registry/missing-data"){
                console.log('no profile case');
                return false;
            }
            throw error;
        });
}

export function getAutoLoginProfile(){
    let headers = {
        "Content-Type": 'application/json'
    };
    return fetch("/wauth/galp", {
        method: "GET",
        headers: headers
    })
    .then(response => {
        if (response.ok) {
            return response.json();
        } else {
            return getErrorResponse(response)
        }
    });
}

export function getLoginProfile(profileId, tokenId){
    let headers = {
        "Content-Type": 'application/json',
        Authorization: "Bearer " + tokenId
    };
    return fetch("/wauth/glp?profileId=" + profileId, {
        method: "GET",
        headers: headers
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                return getErrorResponse(response)
            }
        });
}


export function resetUserTokens(idt, rt){
    return fetch("/reut", {
        method: "POST",
        headers: {
            "Content-Type": 'application/json',
        },
        body: JSON.stringify({
            "fb_id_token": idt,
            "fb_refresh_token": rt
        })
    })
        .then(response => {
            if (response.ok) {
                return;
            } else {
                return getErrorResponse(response)
            }
        }).catch((error)=>{
            console.log(error.stack);
            throw error;
        });
}

export function syncEmailVerificationStatusWithProfile(idToken){
    return fetch("/sevswp", {
        method: "POST",
        headers: {
            "Content-Type": 'application/json',
        },
        body: JSON.stringify({
            "fb_id_token": idToken
        })
    })
    .then(response => {
        if (Math.floor(response.status / 100) !== 2)
            return getErrorResponse(response)
    })
}

export function switchToExistingAccount(payload, token){

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    };

    if (token) {
        requestOptions.headers['Authorization'] = `Bearer ${token}`;
    }

    return fetch('/steal', requestOptions)
    .then((response) => {
        // Check if the response is a redirect
        if (response.redirected) {
            // Get the redirected URL
            const redirectUrl = response.url;

            // Redirect the client to the new URL
            window.location.href = redirectUrl;
        }
        else
            throw new AppError("Redirect failed", null, errorConstants.LOGIN_SERVER_ERROR_GENERIC)

    })
}

export function getResetPasswordLink(email, continueUrl){

    var data = {
        "continueUrl" : continueUrl,
        "email": email,
        "lang": getLang()
    }

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    };

    return fetch('/forgot-password', requestOptions)
    .then(response => {
        if (Math.floor(response.status / 100) !== 2)
            return getErrorResponse(response)
    })
    .catch((error) => {
        console.log(error.stack);
        throw error;
    });
}

