export class AppError extends Error {
    constructor(message, detail = null, errorCode = null, violations = null) {
        super(message);
        this.message = message;
        this.detail = detail;
        this.code = errorCode;
        this.violations = violations;
        this.stack = (new Error()).stack;
    }
}
