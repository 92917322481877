import React, {useState} from "react";

const PageContext = React.createContext({
    loaderState: {},
    showLoader: (message) => {},
    hideLoader: () => {},
    errorState: {},
    showError: (errorMessage) => {},
    dismissError: () => {}
});

const PageContextProvider = (props) => {

    const [loaderState, setLoaderState] = useState({
        isLoading: false,
        message: 'Loading...'
    });

    const [errorState, setErrorState] = useState({
        isError: false,
        message: '',
        onDismiss: null
    });

    const showLoader = (message) => {
        setLoaderState({
            isLoading: true,
            message: message
        })
    }

    const hideLoader = () => {
        setLoaderState({
            isLoading: false,
            message: ''
        })
    }

    const showError = (errorMessage, onDismiss = null) => {
        setErrorState({
            isError: true,
            message: errorMessage,
            onDismiss: onDismiss
        })
    }

    const dismissError = () => {
        setErrorState({
            isError: false,
            message: '',
            onDismiss: null
        })
    }

    return <PageContext.Provider value={{
        loaderState: loaderState,
        showLoader: showLoader,
        hideLoader: hideLoader,
        errorState: errorState,
        showError: showError,
        dismissError: dismissError
    }}>
        {props.children}
    </PageContext.Provider>
}

export default PageContext
export {PageContextProvider}