import React, {useContext, useEffect} from 'react';
import ReactDOM from 'react-dom/client';

import {loadDefaultLanguage} from '../config/I18nConfiguration';
import {useTranslation} from 'react-i18next';

import UnauthorizedAccessView from '../components/misc/UnauthorizedAccessView';
import requestConfig from "../config/RequestConfig";
import BasePage from "./BasePage";
import PageContext, {PageContextProvider} from "../context/PageContext";
import {HashRouter, MemoryRouter} from "react-router-dom";
import LoginRouteConfiguration from "../config/LoginRouteConfiguration";
import ResetPasswordRouteConfiguration from "../config/ResetPasswordRouteConfiguration";


function ResetPasswordPage(props) {

    const pageContext = useContext(PageContext)
    const {t} = useTranslation();

    useEffect(() => {
        loadDefaultLanguage()
    }, []);


    if (requestConfig['unauth'] == "1") {
        return (
            <UnauthorizedAccessView/>
        );
    }

    return (
        <MemoryRouter>
            <ResetPasswordRouteConfiguration/>
        </MemoryRouter>
    );

}


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    // <React.StrictMode>
        <PageContextProvider>
            <BasePage>
                <ResetPasswordPage/>
            </BasePage>
        </PageContextProvider>
    // </React.StrictMode>
);

export default ResetPasswordPage;
