import React, {useContext} from 'react';
import './LoadingOverlay.scss'
import PageContext from "./../../context/PageContext";

const LoadingOverlay = (props) => {

    const pageContext = useContext(PageContext)

    return (
        <div className="loading-overlay">
            <div className="spinner"></div>
            <div className="loading-msg">{pageContext.loaderState.message}</div>
        </div>
    );
};

export default LoadingOverlay;
