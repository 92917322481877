import React, { useEffect, useState } from 'react';

let requestConfig = {};


const configStore = document.getElementById('config_store');
if (configStore) {
    const inputs = configStore.querySelectorAll('input');
    inputs.forEach((input) => {
        requestConfig[input.name] = input.value;
    });
}

export default requestConfig;
