import React, {useContext, useEffect, useRef, useState} from "react";
import {Button, Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import PageContext from "../../context/PageContext";
import requestConfig from "../../config/RequestConfig";
import {isStringEmpty} from "../../utils/CommonUtils";
import {confirmPasswordReset, checkResetPasswordLinkValidity} from "../../utils/LoginServerUtils";
import * as errorConstants from './../../error/ErrorConstants'
import './../../css/Common.scss'
import styles from "./ResetPassword.module.scss";

function ResetPassword(props) {


    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const pageContext = useContext(PageContext);

    const otcToken = requestConfig['otcToken']
    const passwordRef = useRef();
    const [showPassword, setShowPassword] = useState(false);

    const [passwordError, setPasswordError] = useState({isError: -1, errorMsg: ''});


    // check the validity of the token
    useEffect(() => {

        pageContext.showLoader("checking otc validity")
        checkResetPasswordLinkValidity(otcToken)
            .then(resp => {
                if (resp.valid)
                    pageContext.hideLoader()
                else {
                    // this will never happen
                    pageContext.hideLoader()
                    pageContext.showError("Unexpected error")
                }
            }).catch(error => {
            pageContext.hideLoader()
            handleError(error)
        })
    }, [])

    let validatePasswordField = () => {
        if (isStringEmpty(passwordRef.current.value)) {
            setPasswordError({isError: 1, errorMsg: t('resetPasswordPage.errors.emptyPassword')});
        } else {
            setPasswordError({isError: 0, errorMsg: ''});
        }
    };

    let formHasErrors = () => {
        return passwordError.isError !== 0;
    };

    let hasPasswordError = () => {
        return passwordError.isError === 1;
    }

    let showPasswordClickHandler = () => {
        setShowPassword(!showPassword);
    }

    let submitBtnClickHandler = () => {

        let hasError = passwordError.isError !== 0 ;
        if (hasError) {
            return;
        }

        pageContext.showLoader('Loading..');
        confirmPasswordReset(otcToken, passwordRef.current.value).then((result) => {
        }).then(() => {
            pageContext.hideLoader();
            navigate('/success', {state: {continueUrl: requestConfig['continueUrl']}});
        }).catch((error) => {
            pageContext.hideLoader();
            handleError(error)
        });
    };

    let handleError = (error) => {
        switch (error.code) {

            case errorConstants.RESET_PASSWORD_LINK_EXPIRED:
                navigate('/error', {state: {error: errorConstants.RESET_PASSWORD_LINK_EXPIRED}})
                break
            case errorConstants.RESET_PASSWORD_LINK_INVALID:
                navigate('/error', {state: {error: errorConstants.RESET_PASSWORD_LINK_INVALID}})
                break
            case errorConstants.RESET_PASSWORD_LINK_MALFORMED:
                navigate('/error', {state: {error: errorConstants.RESET_PASSWORD_LINK_MALFORMED}})
                break
            default:
                console.log(error.message)
                console.log(error.stack)
                pageContext.showError(error.code + " :: " + error.message)
        }
    }

    return (<div className="container">
            <div className="mb-4 text-center">
                <h2 className="title">{t('resetPasswordPage.title')}</h2>
            </div>

            <div className="row">
                <form>
                    <div className={`mt-3 mb-4 ${styles["password-container"]}`}>

                        <input type={showPassword ? "text" : "password"} ref={passwordRef} className="il-form-control"
                               onChange={validatePasswordField} onBlur={validatePasswordField}
                               placeholder={t('resetPasswordPage.password.label')}
                               required="required"/>
                        {hasPasswordError() && <div className="il-text-danger">{passwordError.errorMsg}</div>}

                        <div className={`${styles["password-controls"]}`}>
                            <div className={`${styles["show-password"]}`}>
                                <input type="checkbox" required="required"
                                       onClick={showPasswordClickHandler}/>
                                <span>{t('emailLoginPage.password.showPasswordLabel')} </span>
                            </div>

                        </div>
                    </div>


                    <Button type="button" className="il-primary-btn mt-4 w-100"
                            onClick={submitBtnClickHandler} {...{disabled: formHasErrors()}}>
                        {t('resetPasswordPage.submit')}
                    </Button>

                </form>
            </div>
        </div>

    )
}

export default ResetPassword