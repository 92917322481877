import {Routes, Route, Navigate} from 'react-router-dom';
import React from 'react';

import UnauthorizedAccessView from '../components/misc/UnauthorizedAccessView';
import ResetPassword from "../components/reset-password/ResetPassword";
import ResetPasswordError from "../components/reset-password/ResetPasswordError";
import ResetPasswordSuccess from "../components/reset-password/ResetPasswordSuccess";

function ResetPasswordRouteConfiguration() {

return (
  <Routes>
    <Route path="/" element={<ResetPassword />} />
    <Route path="/error" element={<ResetPasswordError />} />
    <Route path="/success" element={<ResetPasswordSuccess />} />
    <Route path="*" element={<UnauthorizedAccessView />} />
  </Routes>
  );
}

export default ResetPasswordRouteConfiguration;