import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import {getLang} from "../utils/CommonUtils";

import enLang from "../i18n/messages/en.json";

i18n.use(initReactI18next).init({
    lng: 'en',
    fallbackLng: 'en',
    debug: false, //TODO :: what is this setting for,
    keySeparator: ".", 
    resources: {
        en: {
            translation: enLang
        }
    },
    interpolation: {
        escapeValue: false
    }
});

const loadLanguage = async (language) => {

    const translation = await import(`./../i18n/messages/${language}.json`);
    i18n.addResourceBundle(language, 'translation', translation);
};

const loadDefaultLanguage = async () => {
    let language = getLang();
    await loadLanguage(language);
    i18n.changeLanguage(language);
};

export default i18n;
export { loadLanguage, loadDefaultLanguage };
