import React, {useContext, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import PageContext from "../../context/PageContext";
import * as errorConstants from './../../error/ErrorConstants'
import {getLang, isStringEmpty} from "../../utils/CommonUtils";
import requestConfig from "../../config/RequestConfig";
// import styles from './ResetPasswordError.module.scss'
import './../../css/Common.scss'

function ResetPasswordError(props) {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const pageContext = useContext(PageContext);

    const errorCode = location.state.error

    function getForgotPasswordLink() {
        const queryParams = {
            lang: getLang(),
            continueUrl: requestConfig['continueUrl']
        };
        const searchParams = new URLSearchParams(queryParams);
        const url = `/forgot-password?${searchParams.toString()}`;

        return url
    }

    return (<div className="container">
        {errorCode === errorConstants.RESET_PASSWORD_LINK_EXPIRED &&
            <div className="text-center">{t('resetPasswordPage.errors.linkExpired')}</div>}
        {errorCode === errorConstants.RESET_PASSWORD_LINK_INVALID &&
            <div className="text-center">{t('resetPasswordPage.errors.linkInvalid')}</div>}
        {errorCode === errorConstants.RESET_PASSWORD_LINK_MALFORMED &&
            <div className="text-center">{t('resetPasswordPage.errors.linkMalformed')}</div>}

        <button type="button" className="il-secondary-btn mt-4 w-100" onClick={() => {
            window.open(getForgotPasswordLink(), '_blank')
        }}>{t('resetPasswordPage.generateNewLink')}</button>

    </div>)
}

export default ResetPasswordError