// appConfiguration.dev.js
const config = {
    pmsBaseUrl: 'https://uat-profile.isha.in/services/pms',
    loginServerBaseUrl: 'https://uat-sso.isha.in',
    firebaseConfig: {
        "apiKey": "AIzaSyAKyV9qMMAtpramCBXBP09Zlubp45DeGsE",
        "authDomain": "uat-sso.isha.in",
        "databaseURL": "https://ishatestauth.firebaseio.com",
        "projectId": "ishatestauth",
        "storageBucket": "ishatestauth.appspot.com",
        "messagingSenderId": "255029082278",
        "appId": "1:255029082278:web:c7a685d25b97c321c7dc67",
        "measurementId": "G-B0SCLS1L3J"
    }
};

export default config;
