import './BasePage.scss'
import React, {useContext} from "react";
import LoadingOverlay from "../components/misc/LoadingOverlay";
import ErrorOverlay from "../components/misc/ErrorOverlay";
import PageContext from "../context/PageContext";
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from "../../images/isha-logo-black.svg";
import './../css/Common.scss';

const MyPage = (props) => {

    const pageContext = useContext(PageContext)

    return (
        <div className="my-container">
            <div className="my-card">
                <div className="logo-container mb-4">
                    <img src={logo} alt="Logo" className="logo" />
                </div>
                {props.children}
            </div>

            {pageContext.loaderState.isLoading && <LoadingOverlay/>}
            {pageContext.errorState.isError && <ErrorOverlay/>}
        </div>
    );
}

export default MyPage