import devConfig from './app-config/config.dev';
import uatConfig from './app-config/config.uat';
import prodConfig from './app-config/config.prod';
import defaultConfig from './app-config/config.default';

let appConfiguration;

if (process.env.MYENV === 'prod') {
    console.log("ENV chosen is Prod");
    appConfiguration = prodConfig;
} else if (process.env.MYENV === 'uat') {
    console.log("ENV chosen is UAT");
    appConfiguration = uatConfig;
} else if (process.env.MYENV === 'dev') {
    console.log("ENV chosen is Dev");
    appConfiguration = devConfig;
} else {
    console.log("No env is chosen");
    appConfiguration = {};
}

appConfiguration = {
    ...defaultConfig,
    ...appConfiguration
}

export default appConfiguration;