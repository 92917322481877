import React, {useContext} from 'react';
import alert_icon from "../../../images/error_alert.svg";
import styles from './ErrorOverlay.module.scss';
// import './ErrorOverlay.scss'
import PageContext from "./../../context/PageContext";
import indiaFlag from "../../../images/india-flag.svg";

const ErrorOverlay = (props) => {

    const pageContext = useContext(PageContext)

    function dismissError(){
        let callbackFn = pageContext.errorState.onDismiss;
        if(callbackFn)
            callbackFn()
        pageContext.dismissError()
    }

    return (
        // <div className="error-overlay">
        //     <div className="error-logo">!</div>
        //     <div className="error-msg">{pageContext.errorState.message}</div>
        //     <button className="dismiss-btn" onClick={dismissError}>Ok</button>
        // </div>

        <div className={`${styles["error-overlay"]}`}>
            <div className={`${styles["error-box"]}`} >
                <div className={`${styles["alert-content"]}`}>
                    {/*<div className="alert-icon h4">!</div>*/}
                    <img className={`${styles["alert-icon"]}`} src={alert_icon} alt="Alert"/>

                    <div className={`${styles["error-message"]}`}>{pageContext.errorState.message}</div>
                </div>
                <button className={`${styles["close-button"]}`} onClick={dismissError}>Close</button>
            </div>
        </div>

    );
};

export default ErrorOverlay;
