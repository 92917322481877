import requestConfig from "../config/RequestConfig";
import {AppError} from "../error/AppError";
import {countriesList} from "./CountriesList"; 

let EMAIL_REGEX =
/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@([a-zA-Z0-9]([-]*[a-zA-Z0-9]+)*)(\.[a-zA-Z0-9]([-]*[a-zA-Z0-9]+)*)*(\.[a-zA-Z0-9]([-]*[a-zA-Z0-9]+)+)$/;

export function isValidEmail(email){
	return EMAIL_REGEX.test(email);
}

export function isStringEmpty(str){
	return (typeof str == "undefined") || str.length==0;
}

export function getLang(){
	let lang = requestConfig['lang'];
	return lang? lang.toLowerCase() : 'en';
}

export function getCountryOfResidence(){
	let cor = requestConfig['cf_ipcountry'];
	return cor? cor : '';
}

export function isIPCountryIndian(){
	let cor = requestConfig['cf_ipcountry'];
	return cor=='IN';
}

function getErrorResponse(response) {
    return response.text()
        .then(respText => {
            const e = JSON.parse(respText);
            throw new AppError(e.message, e.detail, e.type)
        })
        .catch(error => {
            if (error instanceof AppError)
                throw error
            throw new AppError("Unexpected Error", null, errorConstants.LOGIN_SERVER_ERROR_GENERIC)
        })
}

export function getCountryList(){
	//TODO: check cdi api urls
	return fetch("https://cdi-gateway.isha.in/contactinfovalidation/api/countries", {
        method: 'GET',
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        if (response.ok) {
            return response.json();
        } else {
            return getErrorResponse(response);
        }

    }).catch((error)=>{
		console.log(error.stack);
		console.log("returning static list");
		return countriesList;
	});
}

export function getCountryListSorted(){
	return getCountryList().then((countries)=>{
		// sort
		countries.sort(function (a, b) {
			if (a.name < b.name) {
			  return -1;
			}
			if (a.name > b.name) {
			  return 1;
			}
			// names must be equal
			return 0;
		  });
		  countries.unshift(
			countries.splice(
			  countries
				.map(function (e) {
				  return e.name;
				})
				.indexOf("India"),
			  1
			)[0],
			countries.splice(
			  countries
				.map(function (e) {
				  return e.name;
				})
				.indexOf("United States"),
			  1
			)[0]
		  );
		  return countries;
	});
}


export function getRequestingAppDomain(){
	try{
		return new URL(requestConfig['request_url']).hostname;
	}catch(e){
		console.log("failed to parse the app domain of the app", e);
	}
}


export function capitalizeFirstChar(str) {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getTheFirstViolationMessageFromError(e) {
	if (e.violations && e.violations.length > 0) {
		return capitalizeFirstChar(e.violations[0].message);
	}
	return null;
}

export function getLoginHintAsEmail() {
	var val = requestConfig['login_hint']

	if(val && (val.indexOf('@') !== -1)){
		return val.trim();
	}
	return null;
}

export function isValidE164PhoneString(val){
	return /^\+\d+$/.test(val);
}

export function getLoginHintAsPhone() {
	var val = requestConfig['login_hint']

	if(val && isValidE164PhoneString(val)){
		return parseE164PhoneNumber(val);
	}
	return null;
}

export function parseE164PhoneNumber(val){
	// TODO: integrate with googlePhoneLib
	// for now it only supports IN phone numbers
	if(val && val.startsWith("+91")){
		return {
			countryCode: "91",
			number: val.substring(3)
		}
	}
	return null;
}