import React, {useContext, useEffect} from "react";
import {useTranslation} from "react-i18next";
import PageContext from "../../context/PageContext";
import {useLocation} from "react-router-dom";

function ResetPasswordSuccess(props) {

    const {t} = useTranslation();
    const pageContext = useContext(PageContext);
    const location = useLocation();

    useEffect(() => {

        if(location.state && location.state.continueUrl){
            setTimeout(()=>{
                pageContext.showLoader("Taking back to the calling App")
                window.location.href = location.state.continueUrl
            }, 2000)
        }
    }, [])

    return (<div>
        {t('resetPasswordPage.successMessage')}
    </div>)
}

export default ResetPasswordSuccess