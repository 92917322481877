import React from 'react';
import {useTranslation} from 'react-i18next';
import './UnauthorizedAccessView.scss'

function UnauthorizedAccessView() {
    const {t} = useTranslation();

    return (
        <div className="unauth-msg">{t('unauthPage.title')}</div>
    );
}

export default UnauthorizedAccessView;