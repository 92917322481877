
export const FIREBASE_ERROR_GENERIC = "error.auth/firebase-generic";

export const FACEBOOK_EMAIL_NOT_SHARED = "error.auth/facebook-email-not-shared"
export const ACCOUNT_LINKING_CANCELLED = "error.auth/account-linking-cancelled"
export const ACCOUNT_LINKING_EMAIL_MISMATCH = "error.auth/account-linking/email-mismatch"
export const EMPTY_REDIRECT_RESULT = "error.auth/firebase/empty-redirect-result"

export const RESET_PASSWORD_LINK_EXPIRED = "error.auth/expired-action-code"
export const RESET_PASSWORD_LINK_MALFORMED = "error.auth/malformed-action-code"
export const RESET_PASSWORD_LINK_INVALID = "error.auth/invalid-action-code"

export const INVALID_OTP = "error.auth/invalid-otp"
export const INVALID_OTP_REQUEST_ID = "error.auth/invalid-request-id"

export const USER_NOT_FOUND = "error.auth/user-not-found"
export const USER_DISABLED = "error.auth/user-disabled"


export const LOGIN_SERVER_ERROR_GENERIC = "error.auth/login-server-generic";
